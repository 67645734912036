import { Component } from '@angular/core';

@Component({
  selector: 'app-on-demand',
  templateUrl: './on-demand.component.html',
  styleUrl: './on-demand.component.css'
})
export class OnDemandComponent {

}
