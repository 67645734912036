export const Ethnicity = [
  { id: 'NSPCD', description: 'Not Specified', type: 'General' },
  { id: 'HLSON', description: 'Hispanic or Latino or Spanish Origin', type: 'General' },
  { id: 'NHLSO', description: 'Not Hispanic or Latino or Spanish Origin', type: 'General' },
  { id: 'Refused', description: 'Refused', type: 'TNWITS' },
  { id: 'Central American', description: 'Central American', type: 'TNWITS' },
  { id: 'Dominican', description: 'Dominican', type: 'TNWITS' },
  { id: 'South American', description: 'South American', type: 'TNWITS' },
  { id: 'Other Spanish', description: 'Other Spanish', type: 'TNWITS' },
  { id: 'Cuban', description: 'Cuban', type: 'TNWITS' },
  { id: 'Other', description: 'Other', type: 'TNWITS' },
  { id: 'Unknown', description: 'Unknown', type: 'TNWITS' },
  { id: 'Hispanic-specific origin not specified', description: 'Hispanic-specific origin not specified', type: 'TNWITS' },
  { id: 'Mexican', description: 'Mexican', type: 'TNWITS' },
  { id: 'Not Collected', description: 'Not Collected', type: 'TNWITS' },
  { id: 'Not of Hispanic Origin', description: 'Not of Hispanic Origin', type: 'TNWITS' },
  { id: 'Puerto Rican', description: 'Puerto Rican', type: 'TNWITS' },
  { id: 'Spanish/Hispanic Latino', description: 'Spanish/Hispanic Latino', type: 'TNWITS' },
];

export function ethnicityOptions(type: string | string[]): any[] {
  if (!type) {
    type = 'General';
  }

  if (!Array.isArray(type)) {
    return Ethnicity.filter(item => item.type === type);
  }
  return Ethnicity.filter(item => type.includes(item.type));
}