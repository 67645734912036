import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { CustomDirectivesModule } from 'src/app/_directives/custom-directives.module';
import { AngularMaterialModule } from 'src/app/material-imports';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { OnDemandComponent } from './on-demand/on-demand.component';

const routes: Routes = [
  {
    path: 'ondemand',
    component: OnDemandComponent
  },
];

@NgModule({
  declarations: [
    OnDemandComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    PipesModule,
    RouterModule.forChild(routes),
    CustomDirectivesModule,
    NgxMaterialTimepickerModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TelehealthModule { }
