export const Race = [
  { id: 'AIOAN', description: 'American Indian or Alaska Native', type: 'General' },
  { id: 'ASIAN', description: 'Asian', type: 'General' },
  { id: 'BLKAA', description: 'Black or African American', type: 'General' },
  { id: 'NHOPI', description: 'Native Hawaiian or Other Pacific Islander', type: 'General' },
  { id: 'WHITE', description: 'White', type: 'General' },
  { id: 'DTSPY', description: 'Declined to specify', type: 'General' },
  { id: 'OTHRC', description: 'Other Race', type: 'General' },
  { id: 'Refused', description: 'Refused', type: 'TNWITS' },
  { id: 'American Indian', description: 'American Indian', type: 'TNWITS' },
  { id: 'Alaska Native', description: 'Alaska Native', type: 'TNWITS' },
  { id: 'Asian', description: 'Asian', type: 'TNWITS' },
  { id: 'Black or African American', description: 'Black or African American', type: 'TNWITS' },
  { id: 'White', description: 'White', type: 'TNWITS' },
  { id: 'Native Hawaiian or Other Pacific Islander', description: 'Native Hawaiian or Other Pacific Islander', type: 'TNWITS' },
  { id: 'Other Single Race', description: 'Other Single Race', type: 'TNWITS' },
  { id: 'Multi-racial', description: 'Multi-racial', type: 'TNWITS' },
  { id: 'Unknown', description: 'Unknown', type: 'TNWITS' },
];

export function raceOptions(type: string | string[]): any[] {
  if (!type) {
    type = 'General';
  }

  if (!Array.isArray(type)) {
    return Race.filter(item => item.type === type);
  }
  return Race.filter(item => type.includes(item.type));
}