<mat-tab-group #matTabGroup [@.disabled]="true" style="margin-top: 10px"
  (selectedTabChange)="changeSelectedIndex($event)" [selectedIndex]="selectedIndex">
  <mat-tab label="Profile">
    <ng-container *ngIf="isProfileTabSelected">
      <app-add-edit-patient [patient]="patient" [hasMemberEditAccess]="hasMemberEditAccess"
        (output)="triggerEvent($event)">
      </app-add-edit-patient>
    </ng-container>
  </mat-tab>

  <!-- Insurance -->
  <mat-tab label="Insurance">
    <ng-container *ngIf="isInsuranceTabSelected">
      <app-member-insurance [patient]="patient" [hasMemberEditAccess]="hasMemberEditAccess">
      </app-member-insurance>
    </ng-container>
  </mat-tab>

  <!-- Member Contact Information -->
  <mat-tab label="Contacts" *ngIf="hasContactsViewAccess || hasPhysiciansViewAccess">
    <ng-container *ngIf="isContactsTabSelected">
      <app-member-contacts [patient]="patient" [hasPhysiciansViewAccess]="hasPhysiciansViewAccess"
        [hasPhysiciansAddAccess]="hasPhysiciansAddAccess" [hasPhysiciansEditAccess]="hasPhysiciansEditAccess"
        [hasPhysiciansDeleteAccess]="hasPhysiciansDeleteAccess" [hasContactsViewAccess]="hasContactsViewAccess"
        [hasContactsAddAccess]="hasContactsAddAccess" [hasContactsEditAccess]="hasContactsEditAccess"
        [hasContactsDeleteAccess]="hasContactsDeleteAccess">
      </app-member-contacts>
    </ng-container>
  </mat-tab>

  <!-- Payments Only if the organization has finished stripe setup-->
  <mat-tab label="Payments" *ngIf="
      hasMemberPaymentsViewAccess &&
      orgConfig?.connectedAccountStatus === 'Setup_Complete'
    ">
    <ng-container *ngIf="isPaymentsTabSelected">
      <app-member-payments [patient]="patient" [hasMemberPaymentsAddAccess]="hasMemberPaymentsAddAccess"
        [hasMemberPaymentsEditAccess]="hasMemberPaymentsEditAccess"
        [hasMemberPaymentsDeleteAccess]="hasMemberPaymentsDeleteAccess"
        [hasTherapySessionViewAccess]="hasTherapySessionViewAccess">
      </app-member-payments>
    </ng-container>
  </mat-tab>

  <mat-tab label="Lab Orders" *ngIf="labOrdersEnabled">
    <ng-container *ngIf="isLabOrdersTabSelected">
      <app-lab-orders [patient]="patient"></app-lab-orders>
    </ng-container>
  </mat-tab>

  <!-- Member Assessments -->
  <mat-tab label="Assessments" *ngIf="hasTherapySessionFeature && hasMemberAssessmentViewAccess">
    <ng-container *ngIf="isAssessmentsTabSelected">
      <app-member-assessments [patient]="patient" [isPhysician]="isPhysician"
        [hasHospitalDeviceFeature]="hasHospitalDeviceFeature" [hasMemberEditAccess]="hasMemberEditAccess"
        [hasMemberAssessmentViewAccess]="hasMemberAssessmentViewAccess"
        [hasMemberAssessmentAddAccess]="hasMemberAssessmentAddAccess"
        [hasMemberAssessmentEditAccess]="hasMemberAssessmentEditAccess"
        [hasMemberAssessmentDeleteAccess]="hasMemberAssessmentDeleteAccess"
        [hasAssessmentChartsViewAccess]="hasAssessmentChartsViewAccess"
        [hasAssessmentGuidanceViewAccess]="hasAssessmentGuidanceViewAccess"
        [hasAssessmentGuidanceEditAccess]="hasAssessmentGuidanceEditAccess"
        [hasAssessmentSummaryViewAccess]="hasAssessmentSummaryViewAccess"
        [hasAssessmentSummaryEditAccess]="hasAssessmentSummaryEditAccess"
        [hasAssessmentTakeAddAccess]="hasAssessmentTakeAddAccess" (output)="triggerEvent($event)">
      </app-member-assessments>
    </ng-container>
  </mat-tab>

  <!-- Member Goals -->
  <mat-tab label="Goals" *ngIf="hasTherapySessionFeature && hasGoalsViewAccess">
    <ng-container *ngIf="isGoalsTabSelected">
      <app-member-goals [patient]="patient" [isPhysician]="isPhysician" [hasGoalsViewAccess]="hasGoalsViewAccess"
        [hasGoalsAddAccess]="hasGoalsAddAccess" [hasGoalsEditAccess]="hasGoalsEditAccess"
        [hasGoalsDeleteAccess]="hasGoalsDeleteAccess" (output)="triggerEvent($event)">
      </app-member-goals>
    </ng-container>
  </mat-tab>

  <!-- Member Diagnosis -->
  <mat-tab label="Diagnosis" *ngIf="hasDiagnosisViewAccess">
    <ng-container *ngIf="isDiagnosisTabSelected">
      <app-diagnosis-codes [patient]="patient" [hasDiagnosisViewAccess]="hasDiagnosisViewAccess"
        [hasDiagnosisAddAccess]="hasDiagnosisAddAccess" [hasDiagnosisEditAccess]="hasDiagnosisEditAccess"
        [hasDiagnosisDeleteAccess]="hasDiagnosisDeleteAccess"></app-diagnosis-codes>
    </ng-container>
  </mat-tab>

  <!-- Member Medications -->
  <mat-tab label="Medications" *ngIf="hasMedicationViewAccess">
    <ng-container *ngIf="isMedicationsTabSelected">
      <app-medications [patient]="patient" [prescriptionEnabled]="prescriptionEnabled"
        [hasMedicationViewAccess]="hasMedicationViewAccess" [hasMedicationAddAccess]="hasMedicationAddAccess"
        [hasMedicationEditAccess]="hasMedicationEditAccess" [hasMedicationDeleteAccess]="hasMedicationDeleteAccess">
      </app-medications>
    </ng-container>
  </mat-tab>

  <!-- Member Allergies -->
  <mat-tab label="Allergies" *ngIf="hasAllergiesViewAccess">
    <ng-container *ngIf="isAllergiesTabSelected">
      <app-member-allergies [patient]="patient" [hasAllergiesViewAccess]="hasAllergiesViewAccess"
        [hasAllergiesAddAccess]="hasAllergiesAddAccess" [hasAllergiesEditAccess]="hasAllergiesEditAccess"
        [hasAllergiesDeleteAccess]="hasAllergiesDeleteAccess">
      </app-member-allergies>
    </ng-container>
  </mat-tab>

  <!-- Member History -->
  <mat-tab label="Medical History" *ngIf="hasMemberHistoryViewAccess || hasFamilyHistoryViewAccess">
    <ng-container *ngIf="isMedHistTabSelected">
      <app-history [hasMemberHistoryViewAccess]="hasMemberHistoryViewAccess"
        [hasMemberHistoryEditAccess]="hasMemberHistoryEditAccess"
        [hasFamilyHistoryViewAccess]="hasFamilyHistoryViewAccess"
        [hasFamilyHistoryEditAccess]="hasFamilyHistoryEditAccess" [patient]="patient"></app-history>
    </ng-container>
  </mat-tab>

  <!-- Member Pharmacies -->
  <mat-tab label="Pharmacies">
    <ng-container *ngIf="isPharmacyTabSelected">
      <app-member-pharmacy [patient]="patient"></app-member-pharmacy>
    </ng-container>
  </mat-tab>

  <!-- Member Consent Documents -->
  <mat-tab label="Consent Documents">
    <ng-container *ngIf="isConsentTabSelected">
      <app-member-consent-documents [patient]="patient" (output)="triggerEvent($event)">
      </app-member-consent-documents>
    </ng-container>
  </mat-tab>
</mat-tab-group>