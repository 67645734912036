<div class="row">
    <p style="font-size: 16px; font-weight: 450; margin-left: 10px">
        <a routerLink="/" routerLinkActive="active">
            <em class="material-icons text_align-center" style="font-size: 18px; margin-top: -3px">home</em> </a>&nbsp;
        /
        &nbsp;
        Instant Tele-Health Session
    </p>
</div>

<p>Yet to do - Pick a Member, Verify email, last name, DOB. Then Generate a Tele Health Link, Send the Link to the Member. Request OTP when member clicks the link</p>
