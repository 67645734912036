export const EmploymentStatus = [
  { id: 'UNKWN', description: 'Unknown', type: 'General' },
  { id: 'EMPLY', description: 'Employed', type: 'General' },
  { id: 'FTSTD', description: 'Full Time Student', type: 'General' },
  { id: 'PTSTD', description: 'Part Time Student', type: 'General' },
  { id: 'RETRD', description: 'Retired', type: 'General' },
  { id: 'DISBD', description: 'Disabled', type: 'General' },
  { id: 'HNMKR', description: 'Homemaker', type: 'General' },
  { id: 'UNEMP', description: 'Unemployed', type: 'General' },
  { id: 'Full-time', description: 'Full-time', type: 'TNWITS' },
  { id: 'Armed Forces', description: 'Armed Forces', type: 'TNWITS' },
  { id: 'Part-time', description: 'Part-time', type: 'TNWITS' },
  { id: 'Seeking employment', description: 'Seeking employment', type: 'TNWITS' },
  { id: 'On layoff', description: 'On layoff', type: 'TNWITS' },
  { id: 'Homemaker', description: 'Homemaker', type: 'TNWITS' },
  { id: 'Student', description: 'Student', type: 'TNWITS' },
  { id: 'Retired', description: 'Retired', type: 'TNWITS' },
  { id: 'Inmate of Institution', description: 'Inmate of Institution', type: 'TNWITS' },
  { id: 'Unable to work due to Drv, MH, or A&D disorder', description: 'Unable to work due to Drv, MH, or A&D disorder', type: 'TNWITS' },
  { id: 'Unable to work due to other disorder', description: 'Unable to work due to other disorder', type: 'TNWITS' },
  { id: 'Other', description: 'Other', type: 'TNWITS' },
  { id: 'Unknown', description: 'Unknown', type: 'TNWITS' }
];

export function employmentStatusOptions(type: string | string[]): any[] {
  if (!type) {
    type = 'General';
  }

  if (!Array.isArray(type)) {
    return EmploymentStatus.filter(item => item.type === type);
  }
  return EmploymentStatus.filter(item => type.includes(item.type));
}