export const PreferredLanguage = [
  { id: 'NTSPF', description: 'Not Specified', type: 'General' },
  { id: 'ENGSH', description: 'English', type: 'General' },
  { id: 'SPNSH', description: 'Spanish', type: 'General' },
  { id: 'ARBC', description: 'Arabic', type: 'General' },
  { id: 'BENGI', description: 'Bengali', type: 'General' },
  { id: 'FRNCH', description: 'French', type: 'General' },
  { id: 'GERMN', description: 'German', type: 'General' },
  { id: 'HINDI', description: 'Hindi', type: 'General' },
  { id: 'ITLIN', description: 'Italian', type: 'General' },
  { id: 'JPNES', description: 'Japanese', type: 'General' },
  { id: 'KOREN', description: 'Korean', type: 'General' },
  { id: 'MNCHI', description: 'Mandarin Chinese', type: 'General' },
  { id: 'PNJBI', description: 'Punjabi', type: 'General' },
  { id: 'PRTGS', description: 'Protuguese', type: 'General' },
  { id: 'RUSIN', description: 'Russian', type: 'General' },
  { id: 'OTHER', description: 'Other', type: 'General' },
  { id: 'Other language', description: 'Other language', type: 'TNWITS' },
  { id: 'Arabic', description: 'Arabic', type: 'TNWITS' },
  { id: 'American Sign Language', description: 'American Sign Language', type: 'TNWITS' },
  { id: 'Chinese Mandarin/Cantonese', description: 'Chinese Mandarin/Cantonese', type: 'TNWITS' },
  { id: 'English', description: 'English', type: 'TNWITS' },
  { id: 'French', description: 'French', type: 'TNWITS' },
  { id: 'Hindi/Gujrati/Urdu', description: 'Hindi/Gujrati/Urdu', type: 'TNWITS' },
  { id: 'Indian(General)', description: 'Indian(General)', type: 'TNWITS' },
  { id: 'Italian', description: 'Italian', type: 'TNWITS' },
  { id: 'Kurdish', description: 'Kurdish', type: 'TNWITS' },
  { id: 'Polish', description: 'Polish', type: 'TNWITS' },
  { id: 'Russian', description: 'Russian', type: 'TNWITS' },
  { id: 'Somalian', description: 'Somalian', type: 'TNWITS' },
  { id: 'Spanish', description: 'Spanish', type: 'TNWITS' },
  { id: 'Unknown Language', description: 'Unknown Language', type: 'TNWITS' }
];

export function preferredLanguageOptions(type: string | string[]): any[] {
  if (!type) {
    type = 'General';
  }

  if (!Array.isArray(type)) {
    return PreferredLanguage.filter(item => item.type === type);
  }
  return PreferredLanguage.filter(item => type.includes(item.type));
}